.canvas-container {
  width: 100vw;
  height: 100px;
}

.canvas {
  position: relative;
  z-index: 1;
  background-color: #f5efdf;
}
