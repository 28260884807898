.heavy-font {
  font-family: ivypresto-display, serif;
  font-weight: 700;
  font-style: normal;
}

.light-font {
  font-family: trade-gothic-next, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.black-box {
  background-color: black;
  width: 50px;
  height: 50px;
}

.center-page {
  display: flex;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
}

.horizontally-center-page {
  display: flex;
  justify-content: center;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right;
}

.justify-content-start {
  justify-content: left !important;
}

.big-white {
  pointer-events: none;
  opacity: 1;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.label {
  float: left;
}

.opacity-zero {
  opacity: 0;
}

.black-text {
  color: #000000;
}

.red-text {
  color: #df7777;
}

.portfolio-item-title {
  margin-top: 20px;
}

.half-background {
  width: 100%;
  height: 50vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f5efdf;
  z-index: 0;
}

.main-width {
  z-index: 1;
  font-size: 15px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  max-width: 800px;
}

.portfolio {
  padding: 80px 0px;
}

.contact {
  padding: 80px 0px;
}

.about {
  padding: 80px 0px;
}

.home {
  padding: 80px 0px;
}

.golden-sum {
  padding: 80px 0px;
}

.circles {
  padding: 80px 0px;
}

.cig-quit {
  padding: 80px 0px;
}

.comp-sci-calc {
  padding: 80px 0px;
}

.border-none {
  border: none !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.overlap {
  z-index: 999;
  position: relative;
}

.golden-sum-image {
  display: block;
  width: 300px;
}

.portfolio-content-image {
  width: 11em;
  height: 11em;
  border-radius: 20px;
  transition: 0.5s;
  border-style: solid;
  border-width: 3px;
  border-color: #000000;
}

.animate-from-right {
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromRight;
}

.portfolio-content {
  width: 11em;
}

.portfolio-content-b {
  width: 16em;
}

.portfolio-content-double {
  width: 25em;
}

.navigation-container-a {
  padding-top: 30px;
  margin-right: 40px;
}

.navigation-container-b {
  padding-top: 30px;
  margin-left: 40px;
}

.link {
  text-decoration: none;
}

.link-text {
  font-size: 1.5em;
  line-height: 1.4em;
  transition: 0.5s;
}

.link-about {
  text-decoration: underline;
  text-decoration-color: #000000;
}

.link-about-b {
  text-decoration: none;
}

.link-underline {
  text-decoration: underline;
  text-decoration-color: #000000;
}

.link-text:hover {
  color: #df7777;
  cursor: pointer;
}

.link:hover {
  color: #df7777;
  cursor: pointer;
}

.header-text {
  transition: 0.5s;
  font-size: 3em;
  line-height: 1.1;
  text-align: center;
  margin-bottom: 22px;
  margin-top: 22px;
}

.header-text-b {
  transition: 0.5s;
  font-size: 3em;
  line-height: 1;
  padding-right: 20px;
  text-align: left;
  margin-bottom: 40px;
  margin-top: 22px;
}

.header-text:hover {
  color: #df7777;
}

.header-text-b:hover {
  color: #df7777;
}

.period {
  color: #df7777;
}

.head-shot {
  position: relative;
  z-index: 100;
  width: 200px;
  padding: 12px;
}

.head-shot-container {
  position: relative;
  z-index: 100;
  width: 200px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.head-shot-b {
  opacity: 0.4;
  height: 400px;
}

.blurb {
  text-align: center;
  padding-top: 22px;
  font-size: 1.2em;
}

.blurb-b {
  text-align: center;
  padding-top: 0px;
  font-size: 1.2em;
}

.blurb-c {
  text-align: left;
  font-size: 1.2em;
}

.flex-content-a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-content-b {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-30%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeftB {
  0% {
    transform: translateX(-30%);
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-from-left {
  transition: 0.5s;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromLeft;
}

.slide-from-right {
  transition: 0.5s;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromRight;
}

.header-text {
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromLeft;
}

.header-text-b {
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromLeft;
}

.head-shot-container {
  background-color: #df7777;
  border-radius: 4%;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromRight;
}

.navigation {
  position: relative;
  z-index: 100;
  animation-duration: 1.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromLeftB;
}

.blurb {
  animation-duration: 1.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromRight;
}

.blurb-b {
  animation-duration: 1.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromLeftB;
}

.blurb-c {
  animation-duration: 1.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromLeftB;
}

.portfolio-content-b {
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromRight;
}

.portfolio-content {
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromRight;
}

.canvas {
  margin-top: -50px;
}

.poetry-image {
  border-radius: 50%;
  width: 50%;
  margin: 20px 0px;
}

.divide {
  width: 100px;
  height: 10px;
  background-color: #df7777;
}

.main-width-poetry {
  width: 500px;
}

@media (min-width: 600px) {
  .nudge-up {
    margin-top: -130px;
  }

  .canvas {
    margin-top: 30px;
  }

  .home {
    padding: 0px;
  }

  .header-text {
    text-align: left;
  }

  .center-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .head-shot-container {
    margin-top: calc(-200px / 6);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .flex-content-a {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: row;
  }

  .flex-content-b {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
    flex-wrap: row;
  }

  .portfolio-content-image {
    width: 11em;
    height: 11em;
  }
  .portfolio-content {
    width: 11em;
  }
  .blurb {
    text-align: left;
  }
  .blurb-b {
    text-align: right;
  }
}

@media (min-width: 797px) {
  .link-text:hover {
    color: #df7777;
  }

  .link:hover {
    color: #df7777;
  }

  .header-text {
    font-size: 3.5em;
  }

  .header-text-b {
    font-size: 3.5em;
  }

  .head-shot-container {
    width: 200px;
    margin-top: 2000px;
  }

  .head-shot {
    width: 250px;
  }

  .portfolio-content-image {
    width: 14em;
    height: 14em;
  }

  .portfolio-content {
    width: 14em;
  }

  .portfolio-content-b {
    width: 26em;
  }
}

@media (min-width: 760px) {
  .header-text {
    font-size: 4em;
  }
  .header-text-b {
    font-size: 4em;
  }

  .head-shot-container {
    width: 250px;
    margin-top: calc(-300px / 3.8);
  }

  .head-shot {
    width: 250px;
  }
}
